import React from "react";
import { Route, Switch } from "react-router-dom";

import Login from "./modules/admin-login";
import AdminDashboard from "./modules/admin-dashboard";
import AdminSupport from "./modules/admin-support";
import AdminArticles from "./modules/admin-articles";
import AdminAddArticles from "./modules/admin-add-article";
import AdminEditArticles from "./modules/admin-edit-article";

function AppRouter() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />

      <Route exact path="/dashboard" component={AdminDashboard} />
      <Route exact path="/support" component={AdminSupport} />
      <Route exact path="/articles" component={AdminArticles} />
      <Route exact path="/add-article" component={AdminAddArticles} />
      <Route exact path="/edit-article" component={AdminEditArticles} />
    </Switch>
  );
}

// window.location.href = "/";

export default AppRouter;
