import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
} from "../../shared/custom-react-native";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import CustomDatatable from "../_components/CustomDatatable";
import { Success, Failed } from "../_components/Modal";

const context = "admin";
export default class AdminEditArticle extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Articles",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,

      image: UI.PRODUCT_BOX,
      image_file: null,

      merchant: "",
      name: "",
      price: "",
      imagePath: "",
      quantity: "",
      description: "",
      purchase_limit: "",

      did_upload: false,

      title: "",
      url: "",
      content: "",
      date: "",
    });
  }

  onCreate = async () => {
    const data = localStorage.getItem("article");
    if (data) {
      const article = JSON.parse(data);

      if (article.image) {
        this.setState({
          image: article.image,
          image_file: article.image,
        });
      }

      UI.set_input_value("title", article.title ?? "");
      UI.set_input_value("url", article.url ?? "");
      UI.set_input_value("content", article.content ?? "");
      const date = parseInt(article.date);
      if (!isNaN(date)) {
        UI.set_date_value("date", new Date(date) ?? "");
      }

      this.setState({
        id: article.id,
      });
    }
  };

  submit = async () => {
    UI.clear_errors();
    //no merchant yet
    const { image_file, title, url, content, image, id, date_timestamp, } = this.state;


    if (!title) {
      await UI.error_form("title", "Title is required.");
    }
    if (!url) {
      await UI.error_form("url", "URL is required.");
    }
    if (!content) {
      await UI.error_form("content", "Content is required.");
    }

    if (!date_timestamp) {
      await UI.error_form("date", "Date Published is required.");
    }

    if (!image_file) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Invalid Article Picture"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
      return;
    }
    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    this.show_loading();

    UI.webRequest({
      method: "post",
      url: "articles/" + id,
      params: {
        title,
        content,
        url,
        image: image_file,
        date: date_timestamp,
      },
      onFail: (err) => {
        return;
      },
      onSuccess: async (response) => {
        UI.clear_forms();
        this.setState({
          image: UI.PRODUCT_BOX,
        });
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully updated the article."}
            onDismiss={() => {
              UI.goBack();
              this.hide_modal();
            }}
          />
        );
      },
      onFinish: async () => {
        this.hide_loading();
      },
      isMultiPart: true,
    });
  };

  upload_picture = async () => {
    const file = await UI.get_image();

    this.setState(
      {
        image: file.uri,
        image_file: file.file,
        did_upload: true,
      },
      () => {
        //this.auth_upload();
      }
    );
  };
  render() {
    const _this = this;
    const { height, width, isMobile, did_upload } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Update Article
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below.
            </Text>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <View style={{ height: 120, width: 120 }}>
              <Layout.FilePicker
                _this={_this}
                title={""}
                additional={""}
                isRequired={false}
                text={""}
                state_name={"product_image"}
                onChange={async (file) => {
                  const uri = await UI.get_uri_from_file(file);
                  this.setState(
                    {
                      image: uri,
                      image_file: file,
                      did_upload: true,
                    },
                    () => {
                      //this.auth_upload();
                    }
                  );
                }}
                height={120}
                component={
                  <ImageBackground
                    style={{ height: 120, width: 120, borderRadius: 10 }}
                    source={this.state.image}
                    imageFit={ImageFit.CONTAIN}
                  />
                }
              />
            </View>
            {UI.box(20)}

            <View style={{ flex: 1.5, justifyContent: "center" }}>
              <Text
                style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
              >
                Article Image Uploaded. Recommended Size: 800 px x 800 px
              </Text>
              {UI.box(10)}

              <Layout.Btn
                color={UI.colors.primary}
                text={"Upload Article Image"}
                onClick={() => {
                  this.upload_picture();
                }}
                width={200}
              />
            </View>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Title"}
              placeholder={"Enter Title"}
              state_name={"title"}
              style={{ flex: 1 }}
              isRequired={false}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"URL"}
              placeholder={"Enter URL"}
              state_name={"url"}
              style={{ flex: 1 }}
              isRequired={false}
            />
            {UI.box(10)}
            <Layout.DatePicker
              _this={_this}
              title={"Date Published"}
              isRequired={false}
              placeholder={"mm/dd/yyyy"}
              state_name={"date"}
              date_props={
                {
                  //maxDate: new Date(),
                }
              }
            />
          </UI.Row>

          {UI.box(10)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Content"}
              placeholder={"Enter Content"}
              state_name={"content"}
              style={{ flex: 1 }}
              height={100}
              isRequired={false}
              type={"big"}
            />
          </UI.Row>
          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"UPDATE ARTICLE"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
