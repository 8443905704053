import { mem } from "../custom-react-native";
import { axiosRequestAuth } from "../Components/Request/js";
import APIConstants from "./APIConstants";
import UI from "../Components/UI/js";

/*
    Memory Data
        1. jwt_token = token used to authenticate server
        2. role = used to navigate the currently logged in user



*/

class APINavigation extends APIConstants {
  constructor(props) {
    super(props);
    const { _this } = props;
    this._this = _this;
  }

  _this = null;

  account_check = async () => {
    return new Promise((resolve, reject) => {
      const token = mem.get(this.JWT_TOKEN);
      if (token) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  init = () => {
    return new Promise(async (resolve, reject) => {
      //check if code is existing in url
      if (await this.account_check()) {
        //logged in
        resolve();
      } else {
        this.redirect_to_login();
      }
    });
  };

  redirect_to_login = () => {
    const _this = this._this;
    if (_this) {
      _this.props.history.push("/login");
    }
    //
  };
}

export default APINavigation;
