import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import "./shared/assets/css/main.css";
import "antd/dist/antd.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

ReactDOM.render(<App />, document.getElementById("root"));
reportWebVitals();
