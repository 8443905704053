import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  mem,
} from "../../shared/custom-react-native";
import UI from "../../shared/Components/UI/js";
import StartupLayout from "../_components/StartupLayout";
import InputForm, { Checkbox } from "../_components/InputForm";
const context = "admin";

export default class AdminLogin extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here

      context: context,
      email: "",
      password: "",
    });
  }

  onCreate = async () => {};

  authenticate = async () => {
    UI.clear_errors();

    const { email, password } = this.state;

    if (!email) {
      await UI.error_form("email", "Email is required.");
    }

    if (!UI.validate_email(email)) {
      await UI.error_form("email", "Please enter a valid email address.");
    }
    if (!password) {
      await UI.error_form("password", "Password is required.");
    }

    const error_count = this.state.error_count;

    if (error_count > 0) {
      return;
    }

    this.show_loading();

    UI.webRequest({
      method: "post",
      url: "login",
      params: {
        email: email,
        password: password,
      },
      onFail: (err) => {
        const err_data = err.data;
        const errors = err_data.errors;
        Object.keys(errors).map(async (key, index) => {
          await UI.error_form(key, errors[key][0]);
        });
      },
      onSuccess: async (response) => {
        UI.clear_forms();
        const data = response.data.data;
        const { token } = data;
        await mem.save(UI.Constants.JWT_TOKEN, token);
        UI.goTo("/dashboard");
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const { width, height, isMobile } = this.state;
    const _this = this;
    return (
      <StartupLayout context={context} image={UI.BG_LOGIN} _this={this}>
        <ScrollView>
          <View style={styles.content_container}>
            <Text style={styles.header_1}>Welcome to the Mwell CMS Admin!</Text>
            {UI.box(20)}
            <Text style={styles.header_2}>Please Sign-in to your account.</Text>
            {UI.box(20)}

            <InputForm
              _this={_this}
              title={"Email Address"}
              placeholder={"Email Address"}
              state_name={"email"}
            />
            {UI.box(20)}
            <InputForm
              _this={_this}
              title={"Password"}
              placeholder={"Password"}
              state_name={"password"}
              isPassword={true}
            />
            {UI.box(20)}

            <TouchableOpacity
              onClick={() => {
                this.authenticate();
              }}
              style={styles.blue_btn}
            >
              <Text style={styles.blue_text}>SIGN IN</Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </StartupLayout>
    );
  }
}

const styles = StyleSheet.create({
  sign_up_sub: {
    fontSize: 12,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  sign_up_main: {
    fontSize: 12,
    fontWeight: "bold",
    color: "black",
  },
  sign_up_btn: {
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  blue_text: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
  },
  blue_btn: {
    width: "100%",
    height: 40,
    backgroundColor: UI.colors.secondary,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  forgot_password_text: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  forgot_container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: -68,
    marginBottom: 50,
  },
  header_2: {
    fontSize: 14,
    color: UI.colors.black,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 20,
    color: UI.colors.primary,
    fontWeight: "bold",
  },
  content_container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
  },
});
