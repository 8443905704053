import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../shared/custom-react-native";
import UI from "../../shared/Components/UI/js";
import { animated, Transition, Spring } from "react-spring";
import "./css.css";
const zIndex = 10001;
export default class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speed: 1.10,
    };
  }

  componentDidMount = () => {};

  render() {
    let props = this.props;
    let { visible, title, text } = props;
    return (
      <View
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: zIndex,
          backgroundColor: "rgba(255,255,255,0.85)",
          justifyContent: "center",
          alignItems: "center",
          display: visible ? "flex" : "none",
        }}
      >
        <img
          style={{
            height: 50,
            width: 50,
            userSelect: "none",
            animation: `spin ${this.state.speed}s linear infinite`,
          }}
          src={UI.LOADER}
        />
        <Text style={{ textAlign: "center", fontSize: 20, fontWeight: "bold" }}>
          {title}
        </Text>
        <Text style={{ textAlign: "center" }}>{text}</Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({});
