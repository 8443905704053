import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../shared/custom-react-native";
import UI from "../../shared/Components/UI/js";
import Loading from "./Loading";
import Modal from "./Modal";
export default class StartupLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_loading: false,
      loading_title: "",
      loading_text: "",

      show_modal: false,
      modal_content: "",
    };
  }

  show_modal = (modal_content) => {
    this.setState({
      show_modal: true,
      modal_content: modal_content,
    });
  };

  hide_modal = () => {
    this.setState({
      show_modal: false,
      modal_content: "",
    });
  };

  show_loading = (title, text) => {
    this.setState({
      show_loading: true,
      loading_title: title,
      loading_text: text,
    });
  };

  hide_loading = () => {
    this.setState({
      show_loading: false,
    });
  };

  componentDidMount = () => {
    const _this = this.props._this;
    _this.show_loading = this.show_loading;
    _this.hide_loading = this.hide_loading;


    _this.show_modal = this.show_modal;
    _this.hide_modal = this.hide_modal;
  };

  render() {
    const _this = this.props._this;
    const { width, height, isMobile } = _this.state;
    let side_width = 400 - (400 / width) * 100 + 30;
    const { show_loading, loading_title, loading_text, show_modal } = this.state;
    return (
      <View style={{ ...styles.main_container, height: height }}>
        <Loading
          visible={show_loading}
          title={loading_title}
          text={loading_text}
        />
        <Modal _this={this} visible={show_modal} />
        <View
          style={{
            ...styles.image_container,
            display: isMobile ? "none" : "flex",
          }}
        >
          <ImageBackground
            style={styles.main_image}
            source={this.props.image}
            imageFit={ImageFit.CONTAIN}
          ></ImageBackground>
        </View>

        <View
          style={{
            height: "100%",
            width: isMobile ? "100%" : side_width,
            backgroundColor: "white",
            paddingLeft: isMobile ? 30 : 60,
            paddingRight: isMobile ? 30 : 60,
            boxShadow: "10px 10px 8px 10px #888888",
          }}
        >
          {this.props.children}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  main_image: {
    height: "90%",
    width: "90%",
  },
  main_container: {
    flexDirection: "row",
    width: "100%",
  },
  image_container: {
    height: "100%",
    flex: 1,
    backgroundColor: "#EFF3F7",
    justifyContent: "center",
    alignItems: "center",
  },
});
