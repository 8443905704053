import UI from "../../shared/Components/UI/js";
class DH {
  carespan = {
    get_name: (arr) => {
      const len = arr.length;
      const item = arr[len - 1];
      const last_name = item.family;
      const given_arr = item.given;
      const first_name = given_arr[0];
      let middle_name = "";
      if (given_arr.length > 1) {
        middle_name = given_arr[1];
      }

      return first_name + " " + middle_name + " " + last_name;
    },
    get_name_obj: (arr) => {
      const len = arr.length;
      const item = arr[len - 1];
      const last_name = item.family;
      const given_arr = item.given;
      const first_name = given_arr[0];
      let middle_name = "";
      if (given_arr.length > 1) {
        middle_name = given_arr[1];
      }

      return { first_name, middle_name, last_name };
    },
    get_specialty: (arr) => {
      if (arr.length > 0) {
        return arr[0];
      } else {
        return "-";
      }
    },

    get_date: (date_obj) => {
      const now = new Date(date_obj);
      const date = UI.timestampToDate(now.getTime());
      return date.month + "/" + date.day + "/" + date.year;
    },

    get_date_obj: (yyyymmdd) => {
      const arr = yyyymmdd.split("-");
      const y = arr[0];
      const m = arr[1];
      const d = arr[2];
      let date = new Date();
      date.setFullYear(y);
      date.setMonth(m);
      date.setDate(d);

      return date;
    },

    get_email: (telecom = []) => {
      return telecom.filter((item) => item["system"] == "email")[0].value + "";
    },
    get_phone: (telecom = []) => {
      return telecom.filter((item) => item["system"] == "phone")[0].value + "";
    },
  };

  title = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
}

const DataHelper = new DH();
export default DataHelper;
