import axios from "axios";
import APIConstants from "../../modules/APIConstants";
import mem from "../Memory/js";
const Constants = new APIConstants();

export const axiosRequest = axios.create({
  baseURL: Constants.API_HOST,
  headers: {},
});

export const axiosRequestAuth = axios.create({
  baseURL: Constants.API_HOST,
  headers: {
    Authorization: "Bearer " + mem.get(Constants.JWT_TOKEN),
  },
});
export const axiosRequestWeb = axios.create({
  baseURL: Constants.API_HOST_WEB,
  headers: {
    Authorization: "Bearer " + mem.get(Constants.JWT_TOKEN),
  },
});

export default null;
