import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
} from "../../shared/custom-react-native";
import UI from "../../shared/Components/UI/js";
import { animated, Transition, Spring } from "react-spring";
import "./css.css";
const zIndex = 10002;
export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speed: 1.1,
    };
  }

  componentDidMount = () => {};

  render() {
    let props = this.props;
    let { visible, _this } = props;
    return (
      <View
        style={{
          display: visible ? "flex" : "none",
          ...styles.modal_container,
        }}
      >
        {_this.state.modal_content}
      </View>
    );
  }
}

export class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    let props = this.props;
    const initial = styles.default_modal;
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style }}>
            <img style={{ height: 50, width: 50 }} src={UI.SUCCESS} />

            <Text style={styles.modal_title}>{this.props.title}</Text>
            <Text style={styles.modal_description}>
              {this.props.description}
            </Text>

            <TouchableOpacity
              onClick={() => {
                if (this.props.onDismiss) {
                  this.props.onDismiss();
                }
              }}
              style={styles.modal_black_btn}
            >
              <Text style={styles.modal_btn_text}>Dismiss</Text>
            </TouchableOpacity>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export class Failed extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let props = this.props;
    const initial = styles.default_modal;
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style }}>
            <img style={{ height: 50, width: 50 }} src={UI.FAILED} />

            <Text style={styles.modal_title}>{this.props.title}</Text>
            <Text style={styles.modal_description}>
              {this.props.description}
            </Text>

            <TouchableOpacity
              onClick={() => {
                if (this.props.onDismiss) {
                  this.props.onDismiss();
                }
              }}
              style={styles.modal_black_btn}
            >
              <Text style={styles.modal_btn_text}>Dismiss</Text>
            </TouchableOpacity>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export class DeleteConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let props = this.props;
    const initial = styles.default_modal;
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style, justifyContent: "flex-start" }}>
            <Text style={styles.modal_header_text}>Confirmation</Text>
            {UI.box(20)}
            <Text style={styles.modal_description_text}>{props.text}</Text>
            <View style = {{flex: 1}}></View>
            <View style={{ flexDirection: "row", }}>
              <Button
                color={"#111111"}
                text={"Cancel"}
                onClick={() => {
                  if (props.onCancel) {
                    props.onCancel();
                  }
                }}
              />
              {UI.box(20)}
              <Button
                color={"#c0392b"}
                text={"Delete"}
                onClick={() => {
                  if (props.onDelete) {
                    props.onDelete();
                  }
                }}
              />
            </View>
          </animated.div>
        )}
      </Spring>
    );
  }
}



export class NotApproved extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    let props = this.props;
    const initial = styles.default_modal;
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style }}>
            <img style={{ height: 50, width: 50 }} src={UI.TRANSACTION_ITEM} />

            <Text style={styles.modal_title}>Waiting for approval</Text>
            {UI.box(10)}
            <Text style={{...styles.modal_description, textAlign: "left", width: "100%"}}>
              Kindly wait for 48 hours for our team to validate the documents that you have submitted. Thank you.
            </Text>
            {UI.box(30)}
            <Text style={{...styles.modal_description, textAlign: "left", width: "100%"}}>
              Regards,
            </Text>

            <Text style={{...styles.modal_description, textAlign: "left", width: "100%"}}>
              MWell Management
            </Text>

            <TouchableOpacity
              onClick={() => {
                if (this.props.onDismiss) {
                  this.props.onDismiss();
                }
              }}
              style={styles.modal_black_btn}
            >
              <Text style={styles.modal_btn_text}>Dismiss</Text>
            </TouchableOpacity>
          </animated.div>
        )}
      </Spring>
    );
  }
}

const Button = (props = { color: "", text: "", onClick: () => {} }) => {
  return (
    <TouchableOpacity
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      style={{ ...styles.modal_black_btn, backgroundColor: props.color }}
    >
      <Text style={styles.modal_btn_text}>{props.text}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  modal_description_text: {
    color: "#585858",
    fontSize: 16,
    alignSelf: "flex-start",
    fontWeight: "bold",
  },
  modal_header_text: {
    color: "#3E3E3E",
    fontSize: 22,
    fontWeight: "bold",
    alignSelf: "flex-start",
  },
  modal_btn_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: "white",
  },
  modal_black_btn: {
    padding: 20,
    borderRadius: 10,
    backgroundColor: "#111111",
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 20,
  },
  modal_description: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#434343",
    textAlign: "center",
  },
  modal_title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#0E0E0E",
    marginTop: 20,
    textAlign: "center",
  },
  modal_during: {
    opacity: 1,
    minHeight: 220,
    minWidth: 220,
    marginTop: 0,
  },
  modal_before: {
    opacity: 0,
    minHeight: 100,
    minWidth: 100,
    marginTop: 200,
  },
  default_modal: {
    backgroundColor: "white",
    borderRadius: 10,
    ...Shadow._3(),
    zIndex: zIndex + 1,
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    maxWidth: 320,
  },
  modal_container: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
    zIndex: zIndex,
    backgroundColor: "rgba(255,255,255,0.85)",
  },
});
