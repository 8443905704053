import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
  mem,
} from "../../shared/custom-react-native";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import CustomDatatable from "../_components/CustomDatatable";
import { DeleteConfirmation } from "../_components/Modal";

const context = "admin";

export default class AdminArticles extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Articles",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      context: context,
    });
  }

  onCreate = async () => {
    this.get_data();
  };

  get_data = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "articles",
      params: {},
      onFail: (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.previews(data);
        this.setState(
          {
            data,
          },
          () => {
            this.reload_table("articles");
          }
        );
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  previews = async (data) => {
    data.map(async (item, index) => {
      const image = item.image;
      this.setState({
        ["preview_" + item.id]: image,
      });
      this.reload_table("articles");
    });
  };

  delete = async (id) => {
    this.show_modal(
      <DeleteConfirmation
        text={"Are you sure to delete this item?"}
        onCancel={() => {
          this.hide_modal();
        }}
        onDelete={async () => {
          this.hide_modal();
          this.show_loading();
          UI.webRequest({
            method: "delete",
            url: "articles/" + id,
            params: {},
            onFail: async (err) => {},
            onSuccess: async (response) => {},
            onFinish: () => {
              this.get_data();
            },
          });
        }}
      />
    );
  };

  render() {
    const _this = this;
    const { height, width, isMobile } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={{ ...styles.main_box_content, padding: 0 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={950}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <Layout.EntryPicker />
              </View>
            </View>

            <InputForm
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
            />

            <View style={{ alignSelf: "center", marginTop: 8, marginLeft: 10 }}>
              <Layout.Btn
                color={UI.colors.primary}
                text={"Add Article"}
                onClick={() => {
                  UI.goTo("/add-article");
                }}
                height={40}
                width={120}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            state_name={"articles"}
            _this={_this}
            columns={[
              { Preview: "preview" },
              { Title: "title" },
              { "Date Published": "date_published" },
              { "Date Added": "created_at" },
              { Action: "action" },
            ]}
            data={this.state.data.map((item) => {
              const date_added = UI.timestampToDate(
                new Date(item.created_at).getTime()
              );
              const date_published = UI.timestampToDate(parseInt(item.date) + 100000000);
              const image =
                this.state["preview_" + item.id] ?? UI.TRANSACTION_USER;

              return {
                preview: (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <img style={{ height: 35, width: 35 }} src={image} />
                    {UI.box(10)}
                    {item.name}
                  </View>
                ),
                title: item.title,
                email: item.email,
                topic: item.topic,
                concern: item.concern,
                created_at:
                  date_added.month +
                  "/" +
                  date_added.day +
                  "/" +
                  date_added.year,
                date_published:
                  date_published.month +
                  "/" +
                  date_published.day +
                  "/" +
                  date_published.year,
                message: item.message,
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={async () => {
                        localStorage.setItem("article", JSON.stringify(item));
                        UI.goTo("/edit-article");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.EDIT_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(10)}
                    <TouchableOpacity
                      onClick={async () => {
                        this.delete(item.id);
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.DELETE_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            current_page={1}
            number_of_pages={20}
            on_page={(page, callback) => {}}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
