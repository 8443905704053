//Images are stored in public folder
class Images {
  LOGOUT = "/img/logout.png";
  BG = "/img/bg.png";
  BG_2 = "/img/bg_2.png";
  BG_LOGIN = "/img/gfx.png";
  EYE_OPENED = "/img/eye_opened.png";
  EYE_CLOSED = "/img/eye_closed.png";
  THICK_CHECK = "/img/thick_check.png";
  MALE_BG = "/img/male_bg.png";
  CALENDAR_1 = "/img/calendar_1.png";
  KEYS = "/img/keys.png";
  MWELL_LOGO = "/img/mwell_logo.png";
  DOCTOR_PROFILE = "/img/doctor_profile.png";
  FOLDER = "/img/folder.png";
  CALENDAR_2 = "/img/calendar_2.png";
  METRO_WELL = "/img/metro_well.png";
  HOME = "/img/home.png";
  CALENDAR_3 = "/img/calendar_3.png";
  USER = "/img/user.png";
  FILE = "/img/file.png";
  LOCK = "/img/lock.png";
  LOGOUT = "/img/logout.png";
  BELL = "/img/bell.png";
  MENU = "/img/menu.png";
  PAY = "/img/pay.png";
  EXPAND = "/img/expand.png";
  HIDE = "/img/hide.png";
  LEFT_ARROW = "/img/left_arrow.png";
  RIGHT_ARROW = "/img/right_arrow.png";
  PLUS = "/img/plus.png";
  MINUS = "/img/minus.png";
  CHECK = "/img/check.png";
  EDIT = "/img/edit.png";
  TRASH = "/img/trash.png";
  DELETE_BTN = "/img/delete_btn.png";
  EDIT_BTN = "/img/edit_btn.png";
  SEARCH = "/img/search.png";
  NO_INFO = "/img/no_info.png";
  SAMPLE_USER = "/img/sample_user.png";
  ADMIN_LOGIN = "/img/admin_login.png";
  TRANSACTION_USER = "/img/transaction_user.png";
  
  DEFAULT_SERVICE = "/img/default_service.png";
  DEFAULT_HOSPITAL = "/img/default_hospital.png";

  IC_DASH_1 = "/img/ic_dash_1.png";
  IC_DASH_2 = "/img/ic_dash_2.png";
  IC_DASH_3 = "/img/ic_dash_3.png";
  IC_DASH_4 = "/img/ic_dash_4.png";
  IC_DASH_5 = "/img/ic_dash_5.png";
  IC_DASH_6 = "/img/ic_dash_6.png";
  IC_DASH_7 = "/img/ic_dash_7.png";
  IC_DASH_8 = "/img/ic_dash_8.png";
  IC_DASH_9 = "/img/ic_dash_9.png";
  IC_DASH_10 = "/img/ic_dash_10.png";
  IC_DASH_11 = "/img/ic_dash_11.png";
  IC_DASH_12 = "/img/ic_dash_12.png";

  CHEVRON_LEFT = "/img/chevron_left.png";
  CHEVRON_RIGHT = "/img/chevron_right.png";
  DOCTOR_USER = "/img/doctor_user.png";
  CONSULT_PRICE = "/img/consult_price.png";
  PAYMENT_METHOD = "/img/payment_method.png";
  BOOKING_TYPE = "/img/booking_type.png";
  BOOKING_DATE = "/img/booking_date.png";
  BOOKING_TIME = "/img/booking_time.png";
  VIEW_BTN = "/img/view_btn.png";
  SETTINGS = "/img/settings.png";
  ELLIPSIS = "/img/ellipsis.png";
  MESSAGE = "/img/message.png";
  CALENDAR_BTN = "/img/calendar_btn.png";
  CALL_VIDEO = "/img/call_video.png";
  CALL_AUDIO = "/img/call_audio.png";
  CALL_PHONE = "/img/call_phone.png";
  CALL_NOTES = "/img/call_notes.png";
  PRESCRIPTION = "/img/prescription.png";
  USER_GFX = "/img/user_gfx.png";

  SAMPLE_PRODUCT = "/img/sample_product.png";

  DETAILS_ADDRESS = "/img/details_address.png";
  DETAILS_AGE = "/img/details_age.png";
  DETAILS_BIRTHDATE = "/img/details_birthdate.png";
  DETAILS_BLOODTYPE = "/img/details_bloodtype.png";
  DETAILS_BMI = "/img/details_bmi.png";
  DETAILS_GENDER = "/img/details_gender.png";
  DETAILS_HEIGHT = "/img/details_height.png";
  DETAILS_WEIGHT = "/img/details_weight.png";

  DETAIL_QUALIFICATION = "/img/detail_qualification.png";
  DETAILS_HOSPITAL = "/img/details_hospital.png";
  DETAILS_PRC = "/img/details_prc.png";
  DETAILS_SPECIALIZATION = "/img/details_specialization.png";
  DETAILS_HOSPITAL_ADDRESS = "/img/details_hospital_address.png";

  TRANSPARENT = "/img/transparent.png";
  TRANSACTION_ITEM = "/img/transaction_item.png";

  ECOMMERCE_LOGIN = "/img/ecommerce_login.png";

  COMMERCE_INQUIRY = "/img/commerce_inquiry.png";
  COMMERCE_INVENTORY = "/img/commerce_inventory.png";
  COMMERCE_PRODUCTS = "/img/commerce_products.png";
  COMMERCE_TRANSACTIONS = "/img/commerce_transactions.png";
  PRODUCT_BOX = "/img/product_box.png";

  INQUIRY = "/img/inquiry.png";
  LOADER = "/img/loader.png";


  SUCCESS = "/img/success.png";
  FAILED = "/img/failed.png";
  WARNING = "/img/warning.png";

}

export default Images;
