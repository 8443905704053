import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../shared/custom-react-native";
import UI from "../../shared/Components/UI/js";
import InputForm, {
  Checkbox,
  DateForm,
  TimeForm,
} from "../_components/InputForm";
import FilePicker from "./FilePicker";

class Helper {
  Btn = (props = { color: "#00CBFF", onClick: () => {}, text: "" }) => {
    return (
      <TouchableOpacity
        onClick={props.onClick}
        style={{
          ...styles.blue_btn_2,
          backgroundColor: props.color,
          width: props.width,
          height: props.height,
        }}
      >
        <Text style={styles.btn_text_1}>{props.text}</Text>
      </TouchableOpacity>
    );
  };

  SelectPicker = (
    props = {
      title: "",
      isRequired: false,
      noTitle: false,
      state_name: "",
      _this: null,
    }
  ) => {
    let { state_name } = props;
    let _this = props._this ?? { state: {} };

    let form_errors = _this.state.form_errors ?? [];
    let form_messages = _this.state.form_messages ?? [];
    let isError = false;
    if (form_errors.includes(state_name)) {
      isError = true;
    }

    return (
      <View style={{ flex: 1 }}>
        <View style={{ display: props.noTitle ? "none" : "flex" }}>
          <Text style={styles.title}>
            {props.title}
            {props.isRequired && <Text style={styles.asterisk}> *</Text>}
          </Text>
          {UI.box(8)}
        </View>

        <View
          style={{
            ...styles.option_container,
            borderColor: isError ? "#EC7B6F" : "#CACACA",
          }}
        >
          <select
            className={"select_" + state_name}
            onChange={(e) => {
              _this.setState({
                [state_name]: e.target.value,
              });
              if (props.onChange) {
                props.onChange(e.target.value);
              }
            }}
            style={{ height: "100%", width: "100%" }}
          >
            {props.children}
          </select>
        </View>

        {isError && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 5,
            }}
          >
            <img style={{ height: 15, width: 15 }} src={UI.WARNING} />
            <Text style={{ marginLeft: 5, fontSize: 12, color: "#EC7B6F" }}>
              {
                form_messages.filter((f) => {
                  const key = Object.keys(f)[0];
                  return key == state_name;
                })[0][state_name]
              }
            </Text>
          </View>
        )}
      </View>
    );
  };

  EntryPicker = (props = { title: "", isRequired: false, noTitle: true }) => {
    return (
      <View style={{ width: 120 }}>
        <this.SelectPicker {...props}>
          <option value="5">5 entries</option>
          <option value="5">10 entries</option>
          <option value="5">25 entries</option>
          <option value="5">100 entries</option>
        </this.SelectPicker>
      </View>
    );
  };

  DatePicker = (
    props = {
      title: "",
      isRequired: false,
      placeholder: "mm/dd/yyyy",
      state_name: "",
      _this: null,
    }
  ) => {
    let { state_name } = props;
    let _this = props._this ?? { state: {} };

    let form_errors = _this.state.form_errors ?? [];
    let form_messages = _this.state.form_messages ?? [];
    let isError = false;
    if (form_errors.includes(state_name)) {
      isError = true;
    }

    const borderColor = isError ? "#EC7B6F" : "#CACACA";

    return (
      <View style={{ flex: 1 }}>
        <Text style={styles.title}>
          {props.title}
          {props.isRequired && <Text style={styles.asterisk}> *</Text>}
        </Text>
        {UI.box(8)}
        <DateForm
          _this={props._this}
          placeholder={props.placeholder}
          state_name={props.state_name}
          borderColor={borderColor}
        />
        {isError && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 5,
            }}
          >
            <img style={{ height: 15, width: 15 }} src={UI.WARNING} />
            <Text style={{ marginLeft: 5, fontSize: 12, color: "#EC7B6F" }}>
              {
                form_messages.filter((f) => {
                  const key = Object.keys(f)[0];
                  return key == state_name;
                })[0][state_name]
              }
            </Text>
          </View>
        )}
      </View>
    );
  };

  TimePicker = (
    props = {
      title: "",
      isRequired: false,
      placeholder: "mm/dd/yyyy",
      state_name: "",
      _this: null,
    }
  ) => {
    return (
      <View style={{ flex: 1 }}>
        <Text style={styles.title}>
          {props.title}
          {props.isRequired && <Text style={styles.asterisk}> *</Text>}
        </Text>
        {UI.box(8)}

        <TimeForm
          _this={props._this}
          placeholder={props.placeholder}
          state_name={props.state_name}
        />
      </View>
    );
  };

  FilePicker = (
    props = {
      title: "",
      additional: "",
      isRequired: false,
      text: "",
      state_name: "",
      _this: null,
      display_type: "filename_size",
      onChange: (file) => {},
    }
  ) => {
    return <FilePicker {...props} />;
  };

  graph_tooltip = (props = { title: "", color: "", value: "" }) => {
    return (
      "<div style=' padding: 10; border-radius: 10px; min-width: 100px; border-style: none; display: flex; height: 90px; box-shadow: 0px 2px 8px -4px #888888; flex-direction: column; font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; ' > <span style=' margin-top: 10px; margin-left: 10px; font-size: 13px; color: #535353; font-weight: bold; ' >" +
      props.title +
      "</span > <div style='display: flex; flex-direction: row; align-items: center; margin-left: 10px; margin-top: 10px; margin-right: 10px;'> <div style=' height: 15px; width: 15px; background-color: " +
      props.color +
      "; border-radius: 30px; margin-top: 8px; ' ></div> <span style=' margin-top: 10px; margin-left: 10px; font-size: 16px; color: #535353; font-weight: bold; ' >" +
      props.value +
      "</span > </div> </div>"
    );
  };

  SortFilter = (props = { _this: null, state_name: "" }) => {
    let { _this, state_name } = props;
    let active = _this.state["sort_filter_" + state_name]; //can be day, month, year
    if (!active) {
      active = "day";
      if (props.btn_1) {
        active = props.btn_1.text.toLowerCase();
      }
    }
    return (
      <View style={{ flexDirection: "row" }}>
        <TouchableOpacity
          onClick={() => {
            if (props.onChange) {
              props.onChange(props.btn_1.text.toLowerCase());
            }
            if (props.btn_1) {
              props.btn_1.onClick(props.btn_1.text.toLowerCase());
            }
          }}
          style={{
            ...styles.sort_filter,
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            backgroundColor:
              active == props.btn_1.text.toLowerCase() ? "#EDFBFF" : "white",
          }}
        >
          <Text
            style={{
              ...styles.sort_filter_text,
              color:
                active == props.btn_1.text.toLowerCase()
                  ? "#004F99"
                  : "#BFBFBF",
            }}
          >
            {props.btn_1 ? props.btn_1.text : "Year"}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onClick={() => {
            if (props.onChange) {
              props.onChange(props.btn_2.text.toLowerCase());
            }
            if (props.btn_2) {
              props.btn_2.onClick(props.btn_2.text.toLowerCase());
            }
          }}
          style={{
            ...styles.sort_filter,
            marginLeft: -2,
            backgroundColor:
              active == props.btn_2.text.toLowerCase() ? "#EDFBFF" : "white",
          }}
        >
          <Text
            style={{
              ...styles.sort_filter_text,
              color:
                active == props.btn_2.text.toLowerCase()
                  ? "#004F99"
                  : "#BFBFBF",
            }}
          >
            {props.btn_2 ? props.btn_2.text : "Month"}
          </Text>
        </TouchableOpacity>
        {props.btn_4 && (
          <TouchableOpacity
            onClick={() => {
              if (props.onChange) {
                props.onChange(props.btn_4.text.toLowerCase());
              }
              if (props.btn_4) {
                props.btn_4.onClick(props.btn_4.text.toLowerCase());
              }
            }}
            style={{
              ...styles.sort_filter,
              marginLeft: -2,
              backgroundColor:
                active == props.btn_4.text.toLowerCase() ? "#EDFBFF" : "white",
            }}
          >
            <Text
              style={{
                ...styles.sort_filter_text,
                color:
                  active == props.btn_4.text.toLowerCase()
                    ? "#004F99"
                    : "#BFBFBF",
              }}
            >
              {props.btn_4 ? props.btn_4.text : "Month"}
            </Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          onClick={() => {
            if (props.onChange) {
              props.onChange(props.btn_3.text.toLowerCase());
            }
            if (props.btn_3) {
              props.btn_3.onClick(props.btn_3.text.toLowerCase());
            }
          }}
          style={{
            ...styles.sort_filter,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            marginLeft: -2,
            backgroundColor:
              active == props.btn_3.text.toLowerCase() ? "#EDFBFF" : "white",
          }}
        >
          <Text
            style={{
              ...styles.sort_filter_text,
              color:
                active == props.btn_3.text.toLowerCase()
                  ? "#004F99"
                  : "#BFBFBF",
            }}
          >
            {props.btn_3 ? props.btn_3.text : "Day"}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };
}

const styles = StyleSheet.create({
  sort_filter_text: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#BFBFBF",
  },
  sort_filter: {
    height: 35,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 2,
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#C9C9C9",
  },
  fade: {
    color: "#BFBFBF",
  },
  file_text: {
    fontSize: 15,
    fontWeight: "bold",
    color: UI.colors.secondary,
  },
  file_container: {
    width: "100%",
    height: 80,
    backgroundColor: "#EFF3F7",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#C9C9C9",
    borderWidth: 2,
    borderStyle: "dashed",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  asterisk: {
    color: "#FF0000",
  },
  option_container: {
    height: 40,
    width: "100%",
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: 2,
    padding: 5,
    borderColor: "#CACACA",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  btn_text_1: {
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  blue_btn_2: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
});

const Layout = new Helper();
export default Layout;
