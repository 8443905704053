import React from "react";
import Images from "../Images/js";
import { View } from "../../custom-react-native";
import mem from "../Memory/js";
import APINavigation from "../../modules/APINavigation";
import APIConstants from "../../modules/APIConstants";
import { axiosRequestAuth, axiosRequest } from "../Request/js";
import axios from "axios";

import DataHelper from "../../../modules/_components/DataHelper";

const Constants = new APIConstants();

class ui extends Images {
  constructor(props) {
    super(props);
  }

  DataHelper = DataHelper;

  webRequest = async (
    props = {
      method: "",
      url: "",
      params: {},
      onSuccess: () => {},
      onFail: () => {},
      isMultiPart: false,
    }
  ) => {
    let axiosRequestWeb = axios.create({
      baseURL: Constants.API_HOST_WEB,
      headers: {
        Authorization: "Bearer " + mem.get(Constants.JWT_TOKEN),
      },
    });
    if (props.isMultiPart) {
      axiosRequestWeb.defaults.headers.common["Content-Type"] =
        "multipart/form-data";
    }

    let params = props.params;

    if (props.isMultiPart) {
      if (props.params) {
        params = new FormData();
        Object.keys(props.params).map((key) => {
          params.append(key, props.params[key]);
        });
      }
    }

    const response = await axiosRequestWeb[props.method.toLowerCase()](
      props.url,
      params
    ).catch((err) => {
      if (props.onFail) {
        props.onFail(err.response);
      }
      if (props.onFinish) {
        props.onFinish();
      }
    });
    if (response) {
      if (props.onSuccess) {
        props.onSuccess(response);
      }
      if (props.onFinish) {
        props.onFinish();
      }
    }
  };
  newWebRequest = async (
    props = {
      method: "",
      url: "",
      params: {},
      onSuccess: () => {},
      onFail: () => {},
      isMultiPart: false,
    }
  ) => {
    const newRequestWeb = axios.create({
      baseURL: Constants.API_HOST_WEB,
      headers: {
        "Ocp-Apim-Subscription-Key": Constants.SUBSCRIBTION_KEY,
        Authorization: "Bearer " + mem.get(Constants.JWT_TOKEN),
      },
    });
    if (props.isMultiPart) {
      newRequestWeb.defaults.headers.common["Content-Type"] =
        "multipart/form-data";
    }
    const response = await newRequestWeb[props.method.toLowerCase()](
      props.url,
      props.params
    ).catch((err) => {
      if (props.onFail) {
        props.onFail(err.response);
      }
      if (props.onFinish) {
        props.onFinish();
      }
    });
    if (response) {
      if (props.onSuccess) {
        props.onSuccess(response);
      }
      if (props.onFinish) {
        props.onFinish();
      }
    }
  };

  authRequest = async (
    props = {
      method: "",
      url: "",
      params: {},
      onSuccess: () => {},
      onFail: () => {},
      isMultiPart: false,
    }
  ) => {
    if (props.isMultiPart) {
      axiosRequestAuth.defaults.headers.common["Content-Type"] =
        "multipart/form-data";
    }
    const response = await axiosRequestAuth[props.method.toLowerCase()](
      props.url,
      props.params
    ).catch((err) => {
      if (props.onFail) {
        props.onFail(err.response);
      }
      if (props.onFinish) {
        props.onFinish();
      }
    });
    if (response) {
      if (props.onSuccess) {
        props.onSuccess(response);
      }
      if (props.onFinish) {
        props.onFinish();
      }
    }
  };
  newAuthRequest = async (
    props = {
      method: "",
      url: "",
      params: {},
      onSuccess: () => {},
      onFail: () => {},
      isMultiPart: false,
    }
  ) => {
    const newRequestAuth = axios.create({
      baseURL: Constants.API_HOST,
      headers: {
        "Ocp-Apim-Subscription-Key": Constants.SUBSCRIBTION_KEY,
        Authorization: "Bearer " + mem.get(Constants.JWT_TOKEN),
      },
    });

    if (props.isMultiPart) {
      newRequestAuth.defaults.headers.common["Content-Type"] =
        "multipart/form-data";
    }

    const response = await newRequestAuth[props.method.toLowerCase()](
      props.url,
      props.params
    ).catch((err) => {
      if (props.onFail) {
        props.onFail(err.response);
      }
      if (props.onFinish) {
        props.onFinish();
      }
    });
    if (response) {
      if (props.onSuccess) {
        props.onSuccess(response);
      }
      if (props.onFinish) {
        props.onFinish();
      }
    }
  };

  _this = null;
  search = async (url, parameters = {}) => {
    let add_to_link = await this.encodeParams(parameters);
    url = url + "?" + add_to_link;
    this.goTo(url);
  };

  Constants = Constants;

  role_map = {
    //role : equivalent sub slash
    provider: "onboard",
    admin: "admin",
    finance: "finance",
    transaction: "transaction",
    merchant: "e-commerce",
    marketing: "marketing",
  };

  api_call = null;

  axiosRequestAuth = axiosRequestAuth;
  axiosRequest = axiosRequest;

  encodeParams = (parameters = {}) => {
    return new Promise((resolve, reject) => {
      let new_query_string = "";
      let index = 0;
      let parameter_array = [];
      let param_keys = Object.keys(parameters);
      if (param_keys.length == 0) {
        resolve("");
      }
      param_keys.map((param_key) => {
        index++;
        let param_value = parameters[param_key];
        if (!param_value) {
          param_value = "";
        }
        parameter_array.push(param_key + "=" + param_value);
        if (index == param_keys.length) {
          new_query_string = parameter_array.join("&");
          resolve(new_query_string);
        }
      });
    });
  };

  goBack = () => {
    window.history.back();
  };

  goTo = (url) => {
    this._this.props.history.push(url);
  }; /* 
  goTo = (url) => {
    window.location.href = url;
  };
 */
  getWidth = () => {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  };
  getHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight
    );
  };

  colors = {
    primary: "#04CCFF",
    secondary: "#004F99",
    black: "#5D5D5D",
    yellow: "#FCC203",
  };
  box = (size) => {
    return <div style={{ height: size, width: size }}></div>;
  };

  PadView = (props = { _this: null, style: {} }) => {
    const _this = props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;

    return (
      <View
        style={{
          width: "100%",
          paddingRight: paddingX,
          paddingLeft: paddingX,
          ...props.style,
        }}
      >
        {props.children}
      </View>
    );
  };

  checkIfMobile = (_this) => {
    let width = _this.state.width;
    if (width < 720) {
      _this.setState({
        isMobile: true,
      });
    } else {
      _this.setState({
        isMobile: false,
      });
    }
  };

  add_resize_function = (name, method) => {
    let _this = this._this;
    if (_this.resize_functions) {
      let temp_functions = _this.resize_functions.filter(
        (obj) => obj.name != name
      );
      temp_functions.push({
        name,
        method,
      });
      _this.resize_functions = temp_functions;
    } else {
      _this.resize_functions = [];
      this.add_resize_function(name, method);
    }
  };

  add_scroll_function = (name, method) => {
    let _this = this._this;
    if (_this.scroll_functions) {
      let temp_functions = _this.scroll_functions.filter(
        (obj) => obj.name != name
      );
      temp_functions.push({
        name,
        method,
      });
      _this.scroll_functions = temp_functions;
    } else {
      _this.scroll_functions = [];
      this.add_scroll_function(name, method);
    }
  };

  set_input_value = (state_name, value) => {
    const inputs = document.getElementsByClassName("input_" + state_name);
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      input.value = value;
    }
    this._this.setState({
      [state_name]: value,
    });
  };
  set_date_value = (state_name, date) => {
    const timestamp = date.getTime();
    let time = UI.timestampToDate(timestamp);

    time.day = UI.pad2(time.day);
    let value = time.month + "/" + time.day + "/" + time.year;
    const carespan = time.year + "-" + time.month + "-" + time.day;
    this._this.setState({
      [state_name]: value,
      [state_name + "_date"]: date,
      [state_name + "_timestamp"]: timestamp,
      [state_name + "_carespan"]: carespan,
    });
  };

  set_select_value = (state_name, value) => {
    const els = document.getElementsByClassName("select_" + state_name);
    for (let i = 0; i < els.length; i++) {
      const el = els[i];
      el.value = value;
    }
    this._this.setState({
      [state_name]: value,
    });
  };

  form_errors = [];
  form_messages = [];

  error_form = (state_name, message) => {
    return new Promise((resolve, reject) => {
      const _this = this._this;
      clearTimeout(_this.error_timeout);
      _this.setState({
        error_count: (_this.state.error_count ?? 0) + 1,
      });

      this.form_errors.push(state_name);
      this.form_messages.push({ [state_name]: message });

      _this.error_timeout = setTimeout(() => {
        _this.setState({
          form_errors: this.form_errors,
          form_messages: this.form_messages,
        });

        //clear errors after 10 seconds
        _this.error_timeout = setTimeout(this.clear_errors, 10000);
      }, 100);
      resolve();
    });
  };

  clear_errors = () => {
    const _this = this._this;
    _this.setState({
      form_errors: [],
      form_messages: [],
      error_count: 0,
    });
    this.form_errors = [];
    this.form_messages = [];
  };

  clear_forms = () => {
    const inputs = document.getElementsByTagName("input");
    this.clear_el(inputs);
    const textareas = document.getElementsByTagName("textarea");
    this.clear_el(textareas);
  };

  clear_el = (els) => {
    for (let i = 0; i < els.length; i++) {
      const el = els[i];
      el.value = "";
    }
  };

  initiateView = (_this, initialState = {}) => {
    this._this = _this;
    _this.resize_functions = [];
    _this.scroll_functions = [];
    _this.error_form = this.error_form;
    _this.clear_errors = this.clear_errors;
    _this.reload_table = this.reload_table;
    _this.add_resize_function = this.add_resize_function;
    _this.add_scroll_function = this.add_scroll_function;
    _this.execute_scrolls = this.execute_scrolls;

    _this.error_timeout = setTimeout(() => {}, 1);

    _this.state = {
      width: this.getWidth(),
      height: this.getHeight(),
      isMobile: this.getWidth() < 720 ? true : false,
      ...initialState,
    };
    _this.componentDidMount = async () => {
      _this.resize = this.resize;
      _this.execute_resizes = this.execute_resizes;

      window.addEventListener("resize", () => {
        this.execute_resizes(_this);
      });

      const navigation_blocker = new APINavigation({ _this });
      await navigation_blocker.init();

      if (_this.onCreate) {
        _this.onCreate();
      }
    };

    _this.componentWillUnmount = () => {
      window.removeEventListener("resize", () => {
        this.execute_resizes(_this);
      });
    };
  };

  reload_table = (state_name) => {
    const table = this._this["table_" + state_name];
    table.set_data();
  };

  execute_resizes = (_this) => {
    this.resize(_this);
    if (_this.on_resize) {
      _this.on_resize();
    }
    if (_this.resize_functions) {
      _this.resize_functions.map((item) => {
        item.method();
      });
    }
  };

  execute_scrolls = (_this) => {
    if (_this.scroll_functions) {
      _this.scroll_functions.map((item) => {
        item.method();
      });
    }
  };

  resize(_this) {
    const width = this.getWidth();
    if (width !== _this.state.width) {
      _this.setState({
        width: width,
        height: this.getHeight(),
      });
      this.checkIfMobile(_this);
    }
  }

  pad2 = (number) => {
    return (number < 10 ? "0" : "") + number;
  };

  measure = (id) => {
    let el = document.getElementById(id);
    if (el) {
      let bounds = el.getBoundingClientRect(el);
      return bounds;
    }
    return {};
  };

  uniqid = (prefix = "", random = false) => {
    const sec = Date.now() * 1000 + Math.random() * 1000;
    const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
    return `${prefix}${id}${
      random ? `.${Math.trunc(Math.random() * 100000000)}` : ""
    }`;
  };

  Row = (props = { _this: null, style: {} }) => {
    const _this = props._this;
    const isMobile = _this.state.isMobile;
    let width = _this.state.width;
    if (props.basis) {
      width = props.basis;
    }
    let flexDirection = "row";
    if (isMobile) {
      flexDirection = "column";
    } else {
      flexDirection = "row";
    }
    if (props.breakpoint != undefined) {
      if (width <= props.breakpoint) {
        flexDirection = "column";
        if (props.breakpoint_2 != undefined) {
          if (width <= props.breakpoint_2) {
            flexDirection = "row";
            if (props.breakpoint_3 != undefined) {
              if (width <= props.breakpoint_3) {
                flexDirection = "column";
              } else {
                flexDirection = "row";
              }
            }
          } else {
            flexDirection = "column";
          }
        }
      } else {
        flexDirection = "row";
      }
    }
    return (
      <View style={{ ...props.style, flexDirection: flexDirection }}>
        {props.children}
      </View>
    );
  };

  Column = (props = { _this: null, style: {} }) => {
    const _this = props._this;
    const isMobile = _this.state.isMobile;
    let width = _this.state.width;
    if (props.basis) {
      width = props.basis;
    }
    let flexDirection = "column";
    if (isMobile) {
      flexDirection = "row";
    } else {
      flexDirection = "column";
    }
    if (props.breakpoint != undefined) {
      if (width <= props.breakpoint) {
        flexDirection = "row";
        if (props.breakpoint_2 != undefined) {
          if (width <= props.breakpoint_2) {
            flexDirection = "column";
            if (props.breakpoint_3 != undefined) {
              if (width <= props.breakpoint_3) {
                flexDirection = "row";
              } else {
                flexDirection = "column";
              }
            }
          } else {
            flexDirection = "row";
          }
        }
      } else {
        flexDirection = "column";
      }
    }
    return (
      <View style={{ ...props.style, flexDirection: flexDirection }}>
        {props.children}
      </View>
    );
  };
  timestampToDate = (timestamp) => {
    timestamp = parseInt(timestamp);
    var dateObj = new Date(timestamp);
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    let h_m_am_pm = dateObj.toLocaleString("en-US", {
      hour: "numeric",
      hour12: true,
      minute: "2-digit",
    });
    let newdate = year + "/" + month + "/" + day + " " + h_m_am_pm;
    let am_pm = h_m_am_pm.split(" ")[1].toLowerCase();
    let h_m = h_m_am_pm.split(" ")[0];
    let h = h_m.split(":")[0];
    let m = h_m.split(":")[1];

    let month_string = "";
    if (month == 1) {
      month_string = "January";
    } else if (month == 2) {
      month_string = "February";
    } else if (month == 3) {
      month_string = "March";
    } else if (month == 4) {
      month_string = "April";
    } else if (month == 5) {
      month_string = "May";
    } else if (month == 6) {
      month_string = "June";
    } else if (month == 7) {
      month_string = "July";
    } else if (month == 8) {
      month_string = "August";
    } else if (month == 9) {
      month_string = "September";
    } else if (month == 10) {
      month_string = "October";
    } else if (month == 11) {
      month_string = "November";
    } else if (month == 12) {
      month_string = "December";
    }


    let obj = {
      year: year,
      month: month,
      day: day,
      am_pm: am_pm,
      h_m: h_m,
      hour: h,
      minute: m,
      month_string: month_string,
    };
    const empty = "-";
    if (isNaN(month)) {
      obj = {
        year: empty,
        month: empty,
        day: empty,
        am_pm: empty,
        h_m: empty,
        hour: empty,
        minute: empty,
        month_string: empty,
      }
    }

    return obj;
  };

  generate_password = () => {
    var length = 16,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  validate_email = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  rand = () => {
    return Math.random() * 100;
  };

  get_param = (param_name) => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get(param_name);
    return c;
  };

  get_file = async () => {
    return new Promise((resolve, reject) => {
      let input = document.createElement("input");
      input.type = "file";

      input.onchange = (ev) => {
        const file = input.files[0];
        resolve(file);
      };
      input.click();
    });
  };
  get_image = async () => {
    return new Promise((resolve, reject) => {
      let input = document.createElement("input");
      input.type = "file";

      input.onchange = (ev) => {
        const file = input.files[0];
        var url = input.value;
        var ext = url.substring(url.lastIndexOf(".") + 1).toLowerCase();
        if (
          input.files &&
          input.files[0] &&
          (ext == "gif" || ext == "png" || ext == "jpeg" || ext == "jpg")
        ) {
          var reader = new FileReader();

          reader.onload = (e) => {
            resolve({ file, uri: e.target.result });
          };
          reader.readAsDataURL(input.files[0]);
        }
      };
      input.click();
    });
  };

  get_uri_from_file = async (file /* files[0] */) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  logout = async () => {
    mem.remove(Constants.JWT_TOKEN);
    this.goTo("/login");
  };

  account_check = async () => {
    return new Promise((resolve, reject) => {
      const token = mem.get(Constants.JWT_TOKEN);
      if (token) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  extract_data = (response) => {
    return response.data.d ?? response.data.data;
  };

  upload_image = async (
    props = {
      url: "",
      file: null,
      onFail: () => {},
      onSuccess: () => {},
    }
  ) => {
    const _this = this._this;

    this.axiosRequestWeb.defaults.headers.common["Content-Type"] =
      "multipart/form-data";
    let formData = new FormData();
    formData.append("image", props.file);
    _this.show_loading("", "Uploading photo...");
    let response = await UI.axiosRequestWeb
      .post(props.url, formData)
      .catch((err) => {
        props.onFail(err.response);
      });

    if (response) {
      props.onSuccess(response.data.d.path, response);
    }

    _this.hide_loading();

    this.axiosRequestWeb.defaults.headers.common["Content-Type"] = null;
  };

  download_image = async (path, container) => {
    return new Promise(async (resolve, reject) => {
      const response = await this.axiosRequestWeb
        .get("admin/image/retrive", {
          path: path,
          container: container,
        })
        .catch((err) => {
          const data = err.response.data;
        });

      if (response.status < 400) {
        resolve(response.data);
      }
    });
  };

  setState = this._this ? this._this.setState : () => {};
}

const UI = new ui();
export default UI;
