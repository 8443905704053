class APIConstants {
  JWT_TOKEN = "jwt_token";


  API_HOST = "https://internal.mwell-backend.highlysucceed.com/api/v1/";
  API_HOST_WEB =
    "https://internal.mwell-backend.highlysucceed.com/api/v1/";


}

export default APIConstants;
