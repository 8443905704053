import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
} from "../../shared/custom-react-native";
import UI from "../../shared/Components/UI/js";
import interpolate from "interpolate-range";
import { animated, Transition, Spring } from "react-spring";
import Loading from "./Loading";
import Modal from "./Modal";

const m = 0.35;
const sidebar_width = 300;

export default class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar_margin: -sidebar_width,
      show_loading: false,
      show_modal: false,
      modal_content: "",

      loading_title: "",
      loading_text: "",

      is_drawer_open: false,
      date_time: "",

      app_content_active: false,
      faq_active: false,
      config_active: false,
      additional: "",
      focused_tab: "",
    };
  }

  show_modal = (modal_content) => {
    this.setState({
      show_modal: true,
      modal_content: modal_content,
    });
  };

  hide_modal = () => {
    this.setState({
      show_modal: false,
      modal_content: "",
    });
  };
  show_loading = (title, text) => {
    this.setState({
      show_loading: true,
      loading_title: title,
      loading_text: text,
    });
  };

  hide_loading = () => {
    this.setState({
      show_loading: false,
    });
  };

  time_interval = clearInterval(() => {}, 1);
  componentDidMount = () => {
    this.props._this.layout = this;

    const _this = this.props._this;
    _this.show_loading = this.show_loading;
    _this.hide_loading = this.hide_loading;
    _this.show_modal = this.show_modal;
    _this.hide_modal = this.hide_modal;
    _this.add_resize_function("main_layout", () => {
      if (this.state.is_drawer_open) {
        this.close_drawer();
      }

      if (document.getElementById("main_layout")) {
        _this.setState({
          current_scroll: document.getElementById("main_layout").scrollTop,
        });
      }
    });
    /*   
    window.onresize = () => {
      if (this.state.is_drawer_open) {
        this.close_drawer();
      }
    };
 */
    this.update_time();
    this.time_interval = setInterval(() => {
      this.update_time();
    }, 5000);

    this.onboard_logic();

    this.set_app_content();
  };

  set_app_content = () => {
    let props = this.props;
    let { context, additional } = props;

    if (
      context == "admin" &&
      ["homescreen", "specialties", "hospitals"].includes(additional)
    ) {
      this.setState({
        app_content_active: true,
      });
    }
    if (context == "admin" && ["categories", "faq"].includes(additional)) {
      this.setState({
        faq_active: true,
      });
    }
    if (
      context == "admin" &&
      ["maintenance_alert", "force_update"].includes(additional)
    ) {
      this.setState({
        config_active: true,
      });
    }
    this.setState({
      additional,
    });
  };

  onboard_logic = () => {
    let onboard_type = UI.get_param("onboard_type");
    this.setState({
      onboard_type: onboard_type,
    });
  };

  componentWillUnmount = () => {
    clearInterval(this.time_interval);
  };

  update_time = async () => {
    let date = UI.timestampToDate(Date.now());
    let new_date =
      date.month_string +
      " " +
      date.day +
      ", " +
      date.year +
      " | " +
      date.h_m +
      "" +
      date.am_pm;
    this.setState({
      date_time: new_date,
    });
  };

  open_drawer = () => {
    this.show_drawer();
  };

  show_timeout = setTimeout(() => {}, 10);

  show_drawer = () => {
    let margin = this.state.sidebar_margin;
    if (margin < 0) {
      this.show_timeout = setTimeout(() => {
        this.setState(
          {
            is_drawer_open: true,
            sidebar_margin: margin + 20,
          },
          () => {
            this.show_drawer();
          }
        );
      }, 1);
    } else {
      clearTimeout(this.show_timeout);
    }
  };

  close_drawer = () => {
    this.hide_drawer();
  };

  hide_timeout = setTimeout(() => {}, 10);

  hide_drawer = () => {
    let margin = this.state.sidebar_margin;
    if (margin > -sidebar_width) {
      this.hide_timeout = setTimeout(() => {
        this.setState(
          {
            is_drawer_open: false,
            sidebar_margin: margin - 50,
          },
          () => {
            this.hide_drawer();
          }
        );
      }, 1);
    } else {
      clearTimeout(this.hide_timeout);
    }
  };

  render() {
    const _this = this.props._this;
    const { height, width, isMobile } = _this.state;
    let { context } = this.props;

    let drawer_bg_style = {};

    const bg_op = interpolate({
      inputRange: [-300, 0],
      outputRange: [0, 0.5],
      clamp: true,
    });
    const bg_opacity = bg_op(this.state.sidebar_margin);

    if (isMobile) {
      if (this.state.is_drawer_open) {
        drawer_bg_style = {
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,1)",
          height: "100%",
          width: "100%",
          opacity: bg_opacity,
        };
      }
    }

    let date_font_size = 14;
    if (width <= 420) {
      date_font_size = 12;
    }
    const { show_loading, loading_title, loading_text, show_modal } =
      this.state;

    return (
      <View style={{ ...styles.main_container }}>
        <Loading
          visible={show_loading}
          title={loading_title}
          text={loading_text}
        />
        <Modal _this={this} visible={show_modal} />

        <SideBar _this={_this} context={this} ctx={context} />

        <View style={{ flex: 1 }}>
          <ScrollView
            onScroll={(scroll) => {
              _this.setState(
                {
                  current_scroll: scroll,
                },
                () => {
                  if (_this.execute_scrolls) {
                    _this.execute_scrolls(_this);
                  }
                }
              );
            }}
            id={"main_layout"}
            style={{ padding: 20 }}
          >
            <UI.Row
              breakpoint={840}
              _this={_this}
              style={styles.header_container}
            >
              <View style={styles.menu_container}>
                <TouchableOpacity
                  onClick={this.open_drawer}
                  style={{
                    ...styles.menu_btn,
                    display: isMobile ? "flex" : "none",
                  }}
                >
                  <Image
                    height={25}
                    width={25}
                    tintColor={UI.colors.primary}
                    source={UI.MENU}
                  />
                </TouchableOpacity>
                <View
                  style={{ flex: 1, display: isMobile ? "flex" : "none" }}
                ></View>
                <Image
                  height={25}
                  width={25}
                  tintColor={UI.colors.primary}
                  source={UI.CALENDAR_3}
                />
                {UI.box(10)}
                <Text
                  style={{ ...styles.header_text, fontSize: date_font_size }}
                >
                  Today is: {this.state.date_time}
                </Text>
              </View>
              {UI.box(10)}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  alignSelf: width > 840 ? "center" : "flex-end",
                }}
              >
                <Image
                  height={25}
                  width={25}
                  tintColor={UI.colors.primary}
                  source={UI.BELL}
                />
                {UI.box(5)}
                <Text style={styles.header_text}>Welcome, Doctor</Text>
                {UI.box(10)}
                <Image
                  height={30}
                  width={30}
                  tintColor={"transparent"}
                  source={UI.DOCTOR_PROFILE}
                />
              </View>
            </UI.Row>

            {this.props.children}
          </ScrollView>
        </View>
      </View>
    );
  }
}

const SideBar = (props = { _this: null, ctx: "" }) => {
  const _this = props._this;
  const context = props.context;
  const context_state = context.state;
  const {
    app_content_active,
    additional,
    faq_active,
    config_active,
    is_drawer_open,
  } = context_state;
  const { height, width, isMobile } = _this.state;

  let sidebar_style = {
    width: sidebar_width,
    ...styles.side_bar,
    height: height,
    zIndex: 10000,
  };

  if (isMobile) {
    sidebar_style = {
      width: sidebar_width,
      ...styles.side_bar,
      height: height,
      position: "absolute",
      height: "100%",
      zIndex: 10000,
    };
  }
  /*   if (isMobile) {
    sidebar_style = {
      width: sidebar_width,
      ...styles.side_bar,
      height: height,
      position: "absolute",
      height: "100%",
      left: context_state.sidebar_margin,
      zIndex: 10000,
    };
  } */

  let ctx = props.ctx;

  const sidebar_content = (
    <ScrollView style={{ padding: 10 }}>
      <View style={{ alignSelf: "center", marginTop: 10 }}>
        <Image
          height={90 * m}
          width={537 * m}
          tintColor={"transparent"}
          source={UI.METRO_WELL}
        />
      </View>

      <View style={{ marginTop: 30, marginBottom: 20 }}>
        <Text style={styles.title}>Dashboard</Text>
      </View>

      <NavBtn
        _this={_this}
        text={"Dashboard"}
        icon={UI.HOME}
        onClick={() => {
          UI.goTo("/dashboard");
        }}
      />

      {["admin"].includes(ctx) && (
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <Text style={styles.title}>CMS Management</Text>
        </View>
      )}

      {["admin"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Support"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/support");
            }}
          />
          <NavBtn
            _this={_this}
            text={"Articles"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/articles");
            }}
          />
        </View>
      )}

      <View style={{ flex: 1 }}></View>
      <View style={{ marginTop: 30 }}>
        <NavBtn
          _this={_this}
          text={"Sign Out"}
          icon={UI.LOGOUT}
          onClick={() => {
            UI.logout();
          }}
          is_active={true}
          color={"#CC0404"}
        />
      </View>
    </ScrollView>
  );

  if (isMobile) {
    return (
      <Transition
        items={is_drawer_open}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
        delay={0}
        config={{ duration: 200 }}
      >
        {(style, item) => {
          if (item) {
            return (
              <animated.div style={{ ...style }}>
                <View
                  style={{
                    height: height,
                    width: width,
                    backgroundColor: "rgba(0,0,0,0.75)",
                    position: "absolute",
                    flexDirection: "row",
                  }}
                >
                  <Transition
                    items={is_drawer_open}
                    from={{ marginLeft: -sidebar_width }}
                    enter={{ marginLeft: 0 }}
                    leave={{ marginLeft: -sidebar_width }}
                    delay={180}
                    config={{ duration: 100 }}
                  >
                    {(style, item) => {
                      return (
                        <animated.div style={style}>
                          <View style={{ ...sidebar_style }}>
                            {sidebar_content}
                          </View>
                        </animated.div>
                      );
                    }}
                  </Transition>
                  <View
                    onClick={() => {
                      context.close_drawer();
                    }}
                    style={{ flex: 1 }}
                  ></View>
                </View>
              </animated.div>
            );
          }
        }}
      </Transition>
    );
  }

  return <View style={sidebar_style}>{sidebar_content}</View>;
};

const NavBtn = (
  props = {
    _this: null,
    text: "",
    icon: "",
    onClick: () => {},
    is_active: false,
    color: UI.colors.primary,
  }
) => {
  let is_active = false;
  let active_tab = props._this.state.active_tab;
  const { _this } = props;

  active_tab == props.text ? (is_active = true) : (is_active = false);

  if (props.is_active) {
    is_active = props.is_active;
  }
  let bg_color = is_active ? UI.colors.primary : "white";

  if (props.color) {
    bg_color = props.color;
  }
  let isFocused = false;

  let focused_tab = "";
  if (_this.layout) {
    focused_tab = _this.layout.state.focused_tab;

    if (focused_tab == props.text) {
      isFocused = true;
    }
  }

  const nav_item = (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        pointerEvents: "none",
      }}
    >
      {is_active && (
        <Image height={25} width={25} tintColor={"white"} source={props.icon} />
      )}
      {!is_active && (
        <Image
          height={25}
          width={25}
          tintColor={"#BFBFBF"}
          source={props.icon}
        />
      )}
      {UI.box(10)}
      <Text
        style={{
          ...styles.nav_btn_text,
          color: is_active ? "white" : "#BFBFBF",
        }}
      >
        {props.text}
      </Text>
    </div>
  );
  if (isFocused) {
    return (
      <Transition
        items={isFocused}
        from={{ paddingLeft: 0 }}
        enter={{ paddingLeft: 20 }}
        leave={{ paddingLeft: 0 }}
      >
        {(style, item) => (
          <animated.div
            onMouseOut={() => {
              setTimeout(() => {
                if (isFocused) {
                  _this.layout.setState({
                    focused_tab: "",
                  });
                }
              }, 10);
            }}
            onClick={props.onClick}
            style={{
              cursor: "pointer",
              display: "inline-flex",
              userSelect: "none",
              ...styles.nav_btn,
              backgroundColor: bg_color,
              ...style,
            }}
          >
            {nav_item}
          </animated.div>
        )}
      </Transition>
    );
  } else {
    return (
      <div
        onMouseOver={() => {
          if (!isFocused) {
            _this.layout.setState({
              focused_tab: props.text,
            });
          }
        }}
      >
        <TouchableOpacity
          onClick={props.onClick}
          style={{
            ...styles.nav_btn,
            backgroundColor: bg_color,
          }}
        >
          {nav_item}
        </TouchableOpacity>
      </div>
    );
  }
};

const styles = StyleSheet.create({
  small_btn_padding: {
    paddingLeft: 45,
    paddingTop: 10,
    paddingBottom: 10,
  },
  additional_btn: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#bfbfbf",
  },
  menu_container: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    alignSelf: "flex-end",
    width: "100%",
  },
  menu_btn: {
    height: 40,
    width: 40,
    justifyContent: "center",
    alignItems: "center",
  },
  header_container: {
    padding: 10,
    width: "100%",
    ...Shadow._3(),
    backgroundColor: "white",
    borderRadius: 5,
    marginBottom: 20,
  },
  header_text: {
    fontWeight: "bold",
    color: "#535353",
  },
  nav_btn_text: {
    fontSize: 16,
    color: "white",
    fontWeight: "bold",
  },
  nav_btn: {
    width: "100%",
    height: 50,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    padding: 10,
    marginBottom: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  side_bar: {
    backgroundColor: "white",
    ...Shadow._3(),
  },
  main_container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#F7F7F7",
    flexDirection: "row",
  },
});
