import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
  mem,
} from "../../shared/custom-react-native";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import CustomDatatable from "../_components/CustomDatatable";
import { DeleteConfirmation } from "../_components/Modal";
import CustomPieChart from "../_components/CustomPieChart";

const context = "admin";

export default class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Dashboard",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      context: context,
    });
  }

  onCreate = () => {};

  render() {
    const _this = this;
    const { height, width, isMobile } = _this.state;

    let user_stats_container = UI.measure("user_stats_container").width;
    return (
      <MainLayout context={context} _this={_this}>
        <UI.Row breakpoint={1070} _this={_this} style={{ width: "100%" }}>
          <View
            id={"user_stats_container"}
            style={{ ...styles.main_content_container, flex: 1, padding: 0 }}
          >
            <UI.Row
              breakpoint={420}
              _this={_this}
              basis={user_stats_container}
              style={{ width: "100%", padding: 20 }}
            >
              <Text style={styles.box_header_text}>User Statistics</Text>
              {UI.box(10)}
              <View>
                <Layout.SortFilter
                  btn_1={{
                    text: "All",
                    onClick: (text) => {},
                  }}
                  btn_2={{
                    text: "Year",
                    onClick: (text) => {},
                  }}
                  btn_3={{
                    text: "Day",
                    onClick: (text) => {},
                  }}
                  btn_4={{
                    text: "Month",
                    onClick: (text) => {},
                  }}
                  onChange={(val) => {
                    _this.setState({
                      ["sort_filter_" + "user_stats"]: val,
                    });
                  }}
                  _this={_this}
                  state_name={"site_stats"}
                />
              </View>
            </UI.Row>

            <CustomPieChart
              data={[
                {
                  color: "#0064C3",
                  label: "New Doctors",
                  count: 10,
                  onSelect: (item) => {},
                },
                {
                  color: "#004F99",
                  label: "Doctors",
                  count: 40,
                  onSelect: (item) => {},
                },
                {
                  color: "#48DAFF",
                  label: "New Patients",
                  count: 15,
                  onSelect: (item) => {},
                },
                {
                  color: "#00CBFF",
                  label: "Patients",
                  count: 105,
                  onSelect: (item) => {},
                },
              ]}
              _this={_this}
              id={"user_stats"}
            />

            <View
              style={{
                alignSelf: "center",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                padding: 20,
              }}
            >
              <UI.Row
                breakpoint={415}
                basis={user_stats_container}
                style={{ flex: 1, justifyContent: "space-around" }}
                _this={_this}
              >
                <Legend color={"#0064C3"} text={"New Doctors"} />
                {UI.box(5)}
                <Legend color={"#004F99"} text={"Doctors"} />
              </UI.Row>
              <UI.Row
                breakpoint={415}
                basis={user_stats_container}
                style={{ flex: 1, justifyContent: "space-around" }}
                _this={_this}
              >
                <Legend color={"#48DAFF"} text={"New Patients"} />
                {UI.box(5)}
                <Legend color={"#00CBFF"} text={"Patients"} />
              </UI.Row>
            </View>
          </View>
        </UI.Row>
      </MainLayout>
    );
  }
}
const Legend = (props = { text: "", color: "" }) => {
  return (
    <View style={styles.legend_container}>
      <View
        style={{ ...styles.dot_color, backgroundColor: props.color }}
      ></View>
      <Text style={styles.legend_text}>{props.text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  legend_text: {
    color: "#535353",
    fontWeight: "bold",
    fontSize: 12,
    marginLeft: 10,
  },
  legend_container: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  dot_color: {
    height: 16,
    width: 16,
    borderRadius: 10,
    backgroundColor: UI.colors.primary,
  },
  main_content_container: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    flex: 1,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
